import Head from "next/head";
import Image from "next/image";

import type { NextPage } from "next";

import TopNavBar from "components/TopNavBar";
import { emberAppBaseUrl } from "utils/envVariables";

import style from "./style.module.scss";

const Page404: NextPage = () => {
  return (
    <div>
      <Head>
        <title>404</title>
        <meta name="robots" content="noindex" />
      </Head>
      <div className={style.container}>
        <TopNavBar />
        <div className={style.content}>
          <div className={style.image_container}>
            <Image
              src="https://assets.smartkarma.com/v3/img/404/404-man.svg"
              alt="confused-looking person"
              width="272"
              height="200"
              priority
            />
          </div>
          <div className={style.text}>
            <h1 className={style.page_heading}> Sorry!</h1>
            <p>
              This page either doesn&apos;t exist,
              <br />
              or it has moved somewhere else.
            </p>
          </div>
          <div className={style.more}>
            <ul className={style.links}>
              <li className={style.links_item}>
                <a
                  href={`${emberAppBaseUrl}/dashboard`}
                  className={style.links_btn}
                >
                  Dashboard
                </a>
              </li>
              <li className={style.links_item}>
                <button
                  className={style.links_btn}
                  onClick={() => history.back()}
                >
                  Back
                </button>
              </li>
              <li className={style.links_item}>
                <a
                  href="mailto:tech@smartkarma.com?subject=404"
                  className={style.links_btn}
                >
                  Report Issue
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
